import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
// import { useUserContext } from "../../context/useUserContext";
import { isPasswordValid, passwordsMatch } from "../../helper/ValidatorsHelper";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import CustomInput from "../../Components/Generic/CustomInput";
import PrimaryButton from "../../Components/Generic/PrimaryButton";
import { motion } from "framer-motion";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/CloseModalIcon.svg";
import { useUserContext } from "../../context/useUserContext";
import { showGenericError } from "../../helper/Notifications";
import { ReactComponent as ShowIcon } from "../../assets/icons/ShowIcon.svg"
import ReactPasswordChecklist from "react-password-checklist";


const ChangePasswordCard = ({ setIsModalOpen }) => {
    const { changePassword, user } = useUserContext();
    const ref = useRef();

    const navigate = useNavigate();
    const { setLoading } = useOutletContext()
    const initialValues = {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        otpCode: "",
    };

    const [inputValues, setInputvalues] = useState(initialValues);
    const [passwordRulesValid, setPasswordRulesValid] = useState(false);
    const [showConditions, setShowConditions] = useState(false);


    const oldPasswordRef = useRef(null);
    const passwordRef = useRef(null);
    const passwordConfRef = useRef(null);

    const handleShowPassword = (ref) => {
        let element = ref.current


        if (element.type === "password") {
            element.type = "text"
        } else {
            element.type = "password"
        }

    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputvalues({
            ...inputValues,
            [name]: value,
        });
    };

    const handleResetBtn = () => {
        let otpValid = true;

        if (!passwordRulesValid) {
            setShowConditions(true);
            return
        }

        if (user.otp_required_for_login) {
            otpValid = inputValues.otpCode.length > 5
        }


        if (
            isPasswordValid(inputValues.newPassword) &&
            passwordsMatch(inputValues.newPassword, inputValues.newPasswordConfirm) &&
            passwordRulesValid &&
            otpValid
        ) {
            setLoading(true);
            changePassword(
                inputValues.oldPassword,
                inputValues.newPassword,
                inputValues.otpCode,
                () => {
                    setLoading(false);
                    navigate("/login", { replace: true });
                },
                (error) => {
                    showGenericError();
                    setLoading(false);
                }
            );
        }
    };

    const handleCreateAccountBtn = () => {
        navigate("/register");
    };



    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [setIsModalOpen]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setIsModalOpen(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);

    return (
        <FullscreenContainer>
            <Card
                ref={ref}
                as={motion.div}
                initial={{ translateX: "65vw" }}
                animate={{ translateX: "0" }}
                exit={{ translateX: "65vw" }}
                transition={{ duration: 0.4 }}
            >
                <Line>
                    <TitleContainer>
                        <h2>New Password</h2>
                    </TitleContainer>
                    <CloneBtn
                        onClick={() => setIsModalOpen(false)}
                        style={{ marginLeft: "auto" }}
                    >
                        <CloseModalIcon />
                    </CloneBtn>

                </Line>

                <Subtitle>
                    <p>Pick a new password to use for your login credentials.</p>
                </Subtitle>
                <InputsWrapper>

                    <CustomInput
                        type={"password"}
                        label={"Old Password"}
                        placeholder="Enter your old password"
                        name={"oldPassword"}
                        value={inputValues.oldPassword}
                        onChange={handleInputChange}
                        inputRef={oldPasswordRef}
                        customButtonStyle={{ marginRight: "10px" }}
                        customButton={
                            <ShowPasswordBtn onClick={() => handleShowPassword(oldPasswordRef)}>
                                <ShowIcon />
                            </ShowPasswordBtn>

                        }
                    />

                    <CustomInput
                        type={"password"}
                        label={"Confirm Password"}
                        placeholder="New password"
                        name={"newPassword"}
                        value={inputValues.newPassword}
                        onChange={handleInputChange}
                        inputRef={passwordRef}
                        customButtonStyle={{ marginRight: "10px" }}
                        customButton={
                            <ShowPasswordBtn onClick={() => handleShowPassword(passwordRef)}>
                                <ShowIcon />
                            </ShowPasswordBtn>

                        }
                    />

                    <CustomInput
                        type={"password"}
                        label={"Confirm Password"}
                        placeholder="New password confirm"
                        name={"newPasswordConfirm"}
                        value={inputValues.newPasswordConfirm}
                        onChange={handleInputChange}
                        inputRef={passwordConfRef}
                        customButtonStyle={{ marginRight: "10px" }}
                        customButton={
                            <ShowPasswordBtn onClick={() => handleShowPassword(passwordConfRef)}>
                                <ShowIcon />
                            </ShowPasswordBtn>

                        }
                    />

                    {user.otp_required_for_login &&
                        <CustomInput
                            type={"text"}
                            label={"OTP Code"}
                            placeholder="Enter OTP Code"
                            name={"otpCode"}
                            value={inputValues.otpCode}
                            onChange={handleInputChange}
                        />
                    }

                    <div style={showConditions ? { display: "flex" } : { display: "none" }}>
                        <ReactPasswordChecklist
                            iconSize={14}
                            minLength={6}
                            value={inputValues.newPassword}
                            valueAgain={inputValues.newPasswordConfirm}
                            rules={["minLength", "specialChar", "capital", "lowercase", "number", "match"]}
                            onChange={(isValid) => { setPasswordRulesValid(isValid) }}
                            className="password-rules"
                        />

                    </div>

                </InputsWrapper>


                <SbmitWrapper>
                    <PrimaryButton
                        styled={{ width: "100%" }}
                        text={"Change password"}
                        onClick={handleResetBtn}
                        disabled={!(isPasswordValid(inputValues.newPassword) && isPasswordValid(inputValues.newPasswordConfirm))}
                    />

                </SbmitWrapper>
            </Card>
        </FullscreenContainer>
    );
};

export default ChangePasswordCard;
const FullscreenContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  min-width: 530px;
  background: ${lightThemeColors.background};
  min-height: 550px;
  gap: 30px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  z-index:1;
      @media(max-width:650px){
  width:100%;
  min-width: unset;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 900;
    font-size: 25px;
  }
  h2 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
  }
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const ForgotPwd = styled.div`
  width: 100%;
  margin-top: -25px;
  p {
    width: fit-content;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;
const SbmitWrapper = styled.div`
  margin-top: auto;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  p {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  span {
    margin-left: 4px;
    font-weight: 700;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;

const Subtitle = styled.div`
  width: 100%;
  display: flex;

  p {
    color: ${lightThemeColors.darkColorFaded};
    opacity: 0.3;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
`;

const CloneBtn = styled.button`
 display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width:100%;
`;

const ShowPasswordBtn = styled.button`
border:none;
background:none;
cursor:pointer;
display:flex;
align-items:center;
jutify-content:center;
`