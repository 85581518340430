import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DELETE_PROJECT, REMOVE_ACCOUNT, REMOVE_KEY, REMOVE_MEMBER } from "../../constants/OptionsConstants";


const DeleteConfirmationChild = ({ action, item, variant, disableButton, children, open, setOpen }) => {


    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        action(item);
        setOpen(false);
    };


    const DisplayDialogVariant = ({ variant }) => {
        let displayDialog = (
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove this item?
            </DialogContentText>)




        if (variant === REMOVE_ACCOUNT
        ) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove current account?
                </DialogContentText>)
        }
        if (variant === REMOVE_MEMBER
        ) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove this member?
                </DialogContentText>)
        }
        if (variant === REMOVE_KEY
        ) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this API key?
                    You won’t be able to access the API without it anymore.
                </DialogContentText>)
        }

        if (variant === DELETE_PROJECT) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove current project?
                </DialogContentText>)
        }


        return displayDialog
    }

    const DisplayConfirmationButton = ({ variant }) => {
        let displayDialog = `Yes`

        if (variant === REMOVE_ACCOUNT) {
            displayDialog = `Remove account`
        }

        return displayDialog
    }
    return (
        (<>
            {children}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                <DialogContent>
                    <DisplayDialogVariant variant={variant} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}> No</Button>
                    <Button style={{ color: "red" }} onClick={handleRemove} autoFocus>
                        <DisplayConfirmationButton variant={variant} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
        )


    );
};

export default DeleteConfirmationChild;



