import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import LocalStorage from '../helper/LocalStorage';
import { useUserContext } from '../context/useUserContext';
import LoaderComponent from '../Components/Generic/LoaderComponent';



const ProtectedRoute = ({ children }) => {

    const { fetchCurrentUser, userRef } = useUserContext();

    const location = useLocation();
    const navigate = useNavigate();

    const authToken = LocalStorage.getAuthenticationToken();


    useEffect(() => {
        if (authToken !== null) {
            fetchCurrentUser(
                function (response) {
                    navigate({
                        pathname: location.state?.backTo,
                        search: location.search,
                    });
                },
                function (error) {
                    navigate(
                        "/login",
                        {
                            search: location.search,
                            state: { backTo: `${location.pathname}` }
                        }
                    )
                }
            );
        }
    }, [])

    if (authToken == null) {
        return (
            <Navigate
                to={"/login"}
                state={
                    {
                        backTo: `${location.pathname}`,
                        search: `${location.search}`
                    }}
            />
        );
    }

    if (authToken) {
        return <>
            {userRef.current ? children : <LoaderComponent loading={true} />}

        </>;
    }
}

export default ProtectedRoute