import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import ProtectedRoute from "./ProtectedRoute";
import ApiKeysPage from "../Pages/ApiKeysPage/ApiKeysPage";
import MembersPage from "../Pages/MembersPage/MembersPage";
import ProfilePage from "../Pages/ProfilePage/ProfilePage";
import DocumentationPage from "../Pages/DocumentationPage/DocumentationPage";
import LoginPage from "../Pages/AuthenticationPage/LoginPage";
import RegisterPage from "../Pages/AuthenticationPage/RegisterPage";
import ResetPasswordPage from "../Pages/AuthenticationPage/ResetPasswordPage";
import NewPasswordPage from "../Pages/AuthenticationPage/NewPasswordPage";
import AcceptInvitePage from "../Pages/AuthenticationPage/AcceptInvite";
import ProjectContextProvider from "../context/useProjectContext";
import KeysContextProvider from "../context/useKeysContext";
import MembersContextProvider from "../context/useMembersContext";
import ProjectPage from "../Pages/ProjectPage/ProjectPage";



export const router = createBrowserRouter([

    {
        index: "*",
        element:
            <ProtectedRoute>
                <ProjectContextProvider>
                    <KeysContextProvider>
                        <MembersContextProvider>
                            <App />
                        </MembersContextProvider>
                    </KeysContextProvider>
                </ProjectContextProvider>
            </ProtectedRoute>
        ,

        children: [
            {
                path: "/api-keys",
                element: <ApiKeysPage />
            },
            {
                path: "/documentation",
                element: <DocumentationPage />
            },
            {
                path: "/members",
                element: <MembersPage />
            },
            {
                path: "/profile",
                element: <ProfilePage />
            },
            {
                path: "/project",
                element: <ProjectPage />
            },

        ]
    },

    {
        path: "/login",
        element: <LoginPage />
    },
    {
        path: "/register",
        element: <RegisterPage />
    },
    {
        path: "/accept-invite",
        element: <AcceptInvitePage />
    },
    {
        path: "/reset_password",
        element: <ResetPasswordPage />
    },
    {
        path: "/new-password",
        element: <NewPasswordPage />
    },


])