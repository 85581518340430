import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from "react";

import { createProjectAPICall, deleteProjectAPICall, getProjectsAPICall, getProjectUsageAPICall } from "../api/projects/projectService";

export const ProjectContext = createContext({
    projects: [],
    selectedProject: [],
    selectedProjectRef: [],
    setProjects: () => { },
    setSelectedProject: () => { },
    getProjects: () => { },
    createProject: () => { },
    getProjectUsage: () => { },
    deleteProject: () => { }


});

const ProjectContextProvider = ({ children, setLoading }) => {
    const [projects, setProjects] = useState(undefined);
    const [selectedProject, setSelectedProject] = useState(undefined);

    const selectedProjectRef = useRef(null);

    const getProjects = useCallback(async (onSuccess, onError) => {
        getProjectsAPICall(
            function (response) {
                setProjects(response.data.response);
                onSuccess(response);
            },
            onError
        );
    });

    const createProject = useCallback(async (name, members, onSuccess, onError) => {
        createProjectAPICall(
            name,
            members,
            function (response) {
                setProjects(response.data.response);
                onSuccess(response);
            },
            onError
        );
    });
    const deleteProject = useCallback(async (projectId, onSuccess, onError) => {
        deleteProjectAPICall(
            projectId,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const getProjectUsage = useCallback(async (projectId, startDate, endDate, onSuccess, onError) => {

        getProjectUsageAPICall(
            projectId,
            startDate, endDate,
            function (response) {

                onSuccess(response);
            },
            onError
        );
    });

    const handleProjectresponse = (response) => {
        setProjects(response.data.project);
    }


    return (
        <ProjectContext.Provider
            value={{
                projects, setProjects,
                selectedProject, setSelectedProject,
                selectedProjectRef,
                getProjects, createProject, deleteProject, getProjectUsage
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export const useProjectContext = () => useContext(ProjectContext);

export default ProjectContextProvider;
