import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    isEmailValid,
    isNameValid,
    isPasswordValid,
    passwordsMatch,
} from "../../helper/ValidatorsHelper";
import { useUserContext } from "../../context/useUserContext";
import { showGenericError } from "../../helper/Notifications";
import CustomInput from "../Generic/CustomInput";
import PrimaryButton from "../Generic/PrimaryButton";
import { ReactComponent as ShowIcon } from "../../assets/icons/ShowIcon.svg"
import ReactPasswordChecklist from "react-password-checklist";


const AcceptInviteCard = ({ setIsLoading }) => {
    const { accpetInvitation } = useUserContext();

    const navigate = useNavigate();
    const initialValues = {
        name: "",
        email: "",
        password: "",
        password_confirm: "",
    };

    const [inputValues, setInputvalues] = useState(initialValues);
    const [nameValid, setNameValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [samePasswords, setSamePasswords] = useState(true);
    const [registerEnabled, setRegisterEnabled] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [passwordRulesValid, setPasswordRulesValid] = useState(false)
    const [showConditions, setShowConditions] = useState(false)
    const passwordRef = useRef(null)
    const passwordConfRef = useRef(null)


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputvalues({
            ...inputValues,
            [name]: value,
        });
    };

    const handleShowPassword = (ref) => {
        let element = ref.current


        if (element.type === "password") {
            element.type = "text"
        } else {
            element.type = "password"
        }

    }


    const handleAcceptInvitation = () => {
        const invitationToken = searchParams.get("token")

        if (!isNameValid(inputValues.name)) {
            setNameValid(false);
        } else {
            setNameValid(true);
        }

        if (!isPasswordValid(inputValues.password)) {
            setPasswordValid(false);
            // return;
        } else {
            setPasswordValid(true);
        }

        if (!passwordRulesValid) {
            setShowConditions(true);
            return
        }

        if (
            isNameValid(inputValues.name) &&
            passwordRulesValid
        ) {

            setIsLoading(true);
            accpetInvitation(
                invitationToken,
                inputValues.name,
                inputValues.password,
                (response) => {
                    // User created
                    setIsLoading(false);
                    //  navigateToDashboardIfPossible();
                    navigate("/");
                },
                () => {
                    setIsLoading(false);
                    showGenericError();
                }
            );
        }
    };

    const goToLogin = () => {
        navigate("/login");
    };

    useEffect(() => {
        if (
            inputValues.name.length > 0 &&
            inputValues.password.length > 0 &&
            inputValues.password_confirm.length > 0
        ) {
            setRegisterEnabled(true);
        } else {
            setRegisterEnabled(false);
        }
    }, [inputValues]);

    return (
        <Card>
            <TitleContainer>
                <h2>Accept invite</h2>
            </TitleContainer>
            <InputsWrapper>
                <CustomInput
                    label={"Name"}
                    placeholder="Enter your name "
                    name={"name"}
                    value={inputValues.name}
                    onChange={handleInputChange}
                    error={!nameValid}
                    errorText={"Name must be at least 3 characters"}
                />

                <CustomInput
                    type={"password"}
                    label={"Create Password"}
                    placeholder="Enter your password"
                    name={"password"}
                    value={inputValues.password}
                    onChange={handleInputChange}
                    inputRef={passwordRef}
                    customButton={
                        <ShowPasswordBtn onClick={() => handleShowPassword(passwordRef)}>
                            <ShowIcon />
                        </ShowPasswordBtn>

                    }
                />

                <CustomInput
                    type={"password"}
                    label={"Confirm Password"}
                    placeholder="Confirm your password"
                    name={"password_confirm"}
                    value={inputValues.password_confirm}
                    onChange={handleInputChange}
                    inputRef={passwordConfRef}
                    customButton={
                        <ShowPasswordBtn onClick={() => handleShowPassword(passwordConfRef)}>
                            <ShowIcon />
                        </ShowPasswordBtn>

                    }
                />


                <div style={showConditions ? { display: "flex" } : { display: "none" }}>
                    <ReactPasswordChecklist
                        iconSize={14}
                        minLength={8}
                        value={inputValues.password}
                        valueAgain={inputValues.password_confirm}
                        rules={["minLength", "specialChar", "capital", "lowercase", "number", "match"]}
                        onChange={(isValid) => { setPasswordRulesValid(isValid) }}
                        className="password-rules"
                    />

                </div>
            </InputsWrapper>

            <SbmitWrapper>
                <PrimaryButton
                    styled={{ width: "100%" }}
                    text={"Get started"}
                    onClick={handleAcceptInvitation}
                    disabled={!registerEnabled}
                />
            </SbmitWrapper>
        </Card>
    );
};

export default AcceptInviteCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  min-width: 530px;
  background: ${lightThemeColors.background};
  min-height: 550px;
  gap: 35px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 40px 0px rgba(151, 168, 190, 0.2);
  z-index: 1;
   @media(max-width:650px){
  width:100%;
  min-width: unset;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 900;
    font-size: 25px;
  }
  h2 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
  }
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const SbmitWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  span {
    margin-left: 4px;
    font-weight: 700;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;

const ShowPasswordBtn = styled.button`
border:none;
background:none;
cursor:pointer;
display:flex;
align-items:center;
jutify-content:center;
margin-right:15px;
`