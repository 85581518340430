import { DELETE, GET, POST } from "../API";

export const getKeysAPICall = (
    projectId,
    onSuccess,
    onError
) => {

    GET(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/api_keys`,
        onSuccess,
        onError
    );
};

export const createKeyAPICall = (
    projectId,
    name,
    onSuccess,
    onError
) => {
    const data = {
        name: name,
    };

    POST(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/create_api_key`,
        data,
        onSuccess,
        onError
    );
};

export const deleteKeyAPICall = (
    projectId,
    keyId,
    onSuccess,
    onError
) => {

    DELETE(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/remove_api_key/${keyId}`,
        onSuccess,
        onError
    );
};