import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { lightThemeColors } from '../../assets/styles/colors';
import PrimaryButton from '../../Components/Generic/PrimaryButton';
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIcon.svg"
import { ReactComponent as CopyIcon } from "../../assets/icons/copyIcon.svg"
import { ReactComponent as DotMenuIcon } from "../../assets/icons/dotMenuIcon.svg"
import SecondaryButton from '../../Components/Generic/SecondaryButton';
import { AnimatePresence } from 'framer-motion';
import InviteMembersModal from '../../Components/MembersPage/InviteMembersModal';
import { useMembersContext } from '../../context/useMembersContext';
import { useProjectContext } from '../../context/useProjectContext';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { showGenericError, showSuccessNotification } from '../../helper/Notifications';
import { useUserContext } from '../../context/useUserContext';
import DatePickerWithOptions from '../../Components/Generic/DatePickerWithOptions';
import moment from 'moment';
import DeleteConfirmationChild from '../../Components/Generic/DeleteConfirmationWithChild';
import { DELETE_PROJECT } from '../../constants/OptionsConstants';
import { lastIndexOf } from 'lodash';

const ProjectPage = () => {
    const { selectedProject, getProjectUsage, deleteProject, getProjects, setSelectedProject } = useProjectContext();
    const { user } = useUserContext()
    const { setLoading } = useOutletContext()

    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)

    const today = new Date()
    const initialDateStart = moment(today).subtract(29, "day").startOf("day").utc();
    const initialDateEnd = moment(today).endOf("day").utc();

    const { setCreateFirstProjectModalOpen } = useOutletContext()
    const [startDate, setStartDate] = useState(new Date(initialDateStart));
    const [endDate, setEndDate] = useState(new Date(initialDateEnd));

    const [usage, setUsage] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();


    const handleGetProjects = () => {
        setLoading(true);
        getProjects(
            (response) => {
                handleProjectResponse(response.data.response);
                setLoading(false);
            },
            (error) => {
                setLoading(false)
                showGenericError();
                console.log(error)
            },
        )
    }

    const handleProjectResponse = (projects) => {
        if (projects.length > 0) {
            const lastInstance = projects[lastIndexOf(projects) - 1];
            setSearchParams((params) => {
                params.set("project_id", lastInstance.id);
                return params;
            });
            setSelectedProject(lastInstance);
            setLoading(false);
        }
        else {
            setCreateFirstProjectModalOpen(true)
        }
    }






    const handleGetUsage = (projectId, startDate, endDate) => {
        let newStartDate = moment(startDate).startOf("day").toISOString()
        let newEndDate = moment(endDate).endOf("day").toISOString()
        setLoading(true)
        getProjectUsage(
            projectId,
            newStartDate, newEndDate,
            (response) => {
                setUsage(response.data.response);
                setLoading(false)
            },
            () => {
                console.log("usage3")
                showGenericError()
                setLoading(false)
            },
        )
    }

    const handleDeleteProject = () => {
        deleteProject(
            selectedProject.id,
            (response) => {
                setSearchParams((prev) => {
                    prev.delete("project_id")
                })
                handleGetProjects()
            },
            (error) => {
                console.log(error)
                showGenericError()
            }
        )
    }

    useEffect(() => {
        if (!selectedProject) {
            return
        }
        handleGetUsage(selectedProject.id, startDate, endDate);

    }, [startDate, endDate, selectedProject])

    return (
        <Container>

            <PageContent>
                <Header>
                    <HeaderTitle>
                        Project
                    </HeaderTitle>
                </Header>
                <ContentWrapper>
                    <Line>
                        <Subtitle>Usage</Subtitle>

                        <div style={{ marginLeft: "auto" }}>
                            <DatePickerWithOptions
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                            />
                        </div>
                    </Line>
                    <ListWrapper>
                        <ListHeader>
                            <HeaderColumn style={{ minWidth: "200px" }}>
                                <p>Name</p>
                            </HeaderColumn>
                            <HeaderColumn>
                                <p>Number of calls</p>
                            </HeaderColumn>
                            <HeaderColumn>
                                <p>Input Tokens</p>
                            </HeaderColumn>
                            <HeaderColumn>
                                <p>Output tokens</p>
                            </HeaderColumn>

                        </ListHeader>
                        {usage?.map((item, index) => (
                            <ListLine key={item.key.id}>
                                <ListColumn style={{ minWidth: "200px" }}>
                                    <h2>  {item.key.name}</h2>
                                </ListColumn>
                                <ListColumn >
                                    <h3>  {item.usage.calls}</h3>
                                </ListColumn>
                                <ListColumn>
                                    <h3>  {item.usage.input_tokens}</h3>
                                </ListColumn>
                                <ListColumn>
                                    <h3>  {item.usage.output_tokens}</h3>
                                </ListColumn>

                            </ListLine>

                        ))}

                    </ListWrapper>
                    <Line>
                        <DeleteConfirmationChild
                            open={deleteConfirmationModalOpen}
                            setOpen={setDeleteConfirmationModalOpen}
                            item={selectedProject}
                            action={handleDeleteProject}
                            variant={DELETE_PROJECT}
                        >
                            <SecondaryButton
                                onClick={() => setDeleteConfirmationModalOpen(true)}
                                variant={lightThemeColors.authentication.inputErrorColor}
                                text={"Delete project"}
                            />
                        </DeleteConfirmationChild>
                    </Line>

                </ContentWrapper>
            </PageContent>
        </Container >
    )
}

export default ProjectPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow:hidden;
  
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow:hidden;
  padding-bottom: 30px;
  padding: 40px 30px;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
display:flex;
align-items:center;
`
const HeaderTitle = styled.h1`
color:${lightThemeColors.darkColor};
font-size: 30px;
font-weight: 600;
line-height: 36px
text-align: left;

`
const ListWrapper = styled.div`
display:flex;
flex-direction:column;
overflow:auto;
`

const Line = styled.div`
display:flex;
align-items:center;
width:100%;
`

const ListHeader = styled(Line)`
background:${lightThemeColors.menuBackground};
padding:13px 20px;
gap:50px;
width:100%;
min-width:750px;
`

const HeaderColumn = styled.div`
display:flex;
width:100%;
min-width:125px;
align-items:center;

p{
color:${lightThemeColors.darkColor50};
font-size: 16px;
font-weight: 600;
line-height: 19px;
text-align: left;
}
`

const ListLine = styled(Line)`
border-bottom:1px solid ${lightThemeColors.menuBackground};
padding:13px 20px;
gap:50px;
width:100%;
min-width:750px;
`
const ListColumn = styled.div`
display:flex;
align-items:center;
width:100%;
min-width:125px;
h3{
text-overflow:ellipsis;
overflow:hidden;
color:${lightThemeColors.darkColor};
font-size: 16px;
font-weight: 400;
line-height: 19px;
}

h2{
text-overflow:ellipsis;
overflow:hidden;
color:${lightThemeColors.darkColor};
font-size: 18px;
font-weight: 600;
line-height: 22px;
}
`

const ContentWrapper = styled.div`
display:flex;
flex-direction:column;
gap:30px;
height:100%;
overflow:auto;
`
const Subtitle = styled.h2`
color:${lightThemeColors.darkColor};
font-size: 24px;
font-weight: 600;
line-height: 30px
text-align: left;
`