import React, { useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import { ReactComponent as DotMenuIcon } from "../../assets/icons/dotMenuIcon.svg"
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import DeleteConfirmationChild from './DeleteConfirmationWithChild'
import { REMOVE_KEY } from '../../constants/OptionsConstants'
import { ReactComponent as Bin } from "../../assets/icons/bin-emotii.svg"

const PopoverMenu = ({ item, handleAction }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

    const handleOnClick = (item) => {
        handleAction(item)
        setIsPopoverOpen(false)
    }


    return (
        <Container>
            <Popover
                isOpen={isPopoverOpen}
                positions={["bottom", "left",]}
                onClickOutside={() => setIsPopoverOpen(false)}
                align="end"
                padding={8}
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                    >
                        <CustomMenu >
                            <DeleteConfirmationChild
                                open={isConfirmationOpen}
                                setOpen={setIsConfirmationOpen}
                                action={handleOnClick}
                                item={item}
                                variant={REMOVE_KEY}
                            >
                                <MenuItem onClick={() => setIsConfirmationOpen(true)}>
                                    Delete key
                                    <Bin />
                                </MenuItem>
                            </DeleteConfirmationChild>
                        </CustomMenu>
                    </ArrowContainer>

                )
                }
            >
                <CustomBtn
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                    <DotMenuIcon />
                </CustomBtn>


            </Popover>
        </Container>
    )
}

export default PopoverMenu
const Container = styled.div`
display:flex;
position:relative;
width:fit-content;
margin-left:auto;
`
const CustomBtn = styled.button`
border:none;
background:none;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
min-width:10px;

`

const CustomMenu = styled.div`
display:flex;
flex-direction:column;
gap:5px;
background:${lightThemeColors.menuBackground};
min-width:60px;
height:100%;
border-radius:5px;

overflow:hidden;
`
const MenuItem = styled.p`
font-size:14px;
font-weight:500;
color:${lightThemeColors.authentication.inputErrorColor};
padding:10px;
display:flex;
align-items:center;
gap:5px;
svg{
height:14px;
}
&:hover{
background:${lightThemeColors.sidebar.menuItemBorder};
cursor:pointer;
}

`