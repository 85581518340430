export const lightThemeColors = {
  darkColor: 'rgba(42, 45, 55, 1)',
  darkColor50: "rgba(42, 45, 55, 0.5)",
  primaryColor: "rgba(112, 105, 253, 1)",
  background: "rgba(255, 255, 255, 1)",
  menuBackground: "rgba(248, 248, 248, 1)",



  authentication: {
    inputBg: "rgba(19, 36, 65, 0.05)",
    inputErrorColor: "rgba(255, 102, 102, 1)",
  },

  sidebar: {
    projectsBg: "rgba(237, 237, 239, 1)",
    menuItemBorder: "rgba(235, 235, 235, 1)",
    selectedItemBg: "rgba(19, 36, 65, 0.1)",
    requiredBg: "rgba(19, 36, 65, 0.05)",
    optionalBg: "rgba(42, 134, 255, 0.1)",
    tagBg: "rgba(42, 134, 255, 0.05)",
    linkBg: "rgba(42, 134, 255, 0.2)",
    elementExpandedBg: "rgba(171, 194, 224, 1)",
    selectedItemAccent: "rgba(19, 36, 65, 1)",
  },


};
