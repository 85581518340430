import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import { ReactComponent as CloseModalIcon } from "../../assets/icons/CloseModalIcon.svg";
import { useSearchParams } from 'react-router-dom';

import SecondaryButton from '../Generic/SecondaryButton';
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIcon.svg"
import { motion } from 'framer-motion';
import { useProjectContext } from '../../context/useProjectContext';
import PrimaryButton from '../Generic/PrimaryButton';

export const ProjectItem = ({ onClick, text, style, selected, }) => {
    return (
        <ItemContainer
            onClick={onClick}
            selected={selected}
            style={style}
        >
            {text &&
                <ItemTextContainer selected={selected}>
                    <p>{text}</p>
                </ItemTextContainer>
            }
        </ItemContainer>
    );
};


const NewProjectSideBar = ({ expandProjectMenu, setCreateNewProjectModalOpen, containerRef }) => {

    const { setSelectedProject, selectedProject, projects } = useProjectContext();
    const [searchParams, setSearchParams] = useSearchParams();


    const handleSelectProject = (project) => {
        setSearchParams((params) => {
            params.set("project_id", project.id);
            return params;
        });
        setSelectedProject(project);
    };

    const handleCreateNewProject = () => {
        setCreateNewProjectModalOpen(true);
        expandProjectMenu();
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (expandProjectMenu && containerRef?.current && !containerRef?.current.contains(e.target)) {
                expandProjectMenu();
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [expandProjectMenu]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                expandProjectMenu()
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);

    return (
        <Container as={motion.div}
            initial={{ width: 0, left: "200px" }}
            animate={{ width: "220px", left: "200px" }}
            exit={{ width: 0, left: "200px" }}
            transition={{ duration: 0.2 }}
        >

            <Line>
                <HeadWrapper>
                    <h1>Projects</h1>
                </HeadWrapper>
                <CloneBtn
                    onClick={() => expandProjectMenu()}
                    style={{ marginLeft: "auto" }}
                >
                    <CloseModalIcon />
                </CloneBtn>
            </Line>
            <Line style={{ marginTop: "10px" }}>
                <PrimaryButton
                    icon={<PlusIcon />}
                    onClick={() => handleCreateNewProject()}
                    styled={{
                        width: "100%", minWidth: "184px"
                    }}
                    text={"Create new project"}
                />
            </Line>
            <ProjectList>
                {projects?.map((project) => (
                    <ProjectItem
                        text={project.name}
                        selected={project.id === selectedProject?.id}
                        key={project.id}
                        onClick={() => handleSelectProject(project)}
                    >
                    </ProjectItem>
                ))}
            </ProjectList>
        </Container>

    )
}

export default NewProjectSideBar
const Container = styled.div`
height:100%;
width:220px;
background:${lightThemeColors.menuBackground};
border-left:1px solid rgba(151, 168, 190, 0.3);
border-right:1px solid rgba(151, 168, 190, 0.3);
position:absolute;
z-index:4;
padding:30px 0 0 0;
gap:25px;
display:flex;
flex-direction:column;
overflow:hidden;
`

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

`;
const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
    padding:0 16px;
`;
const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;

  svg{
  width:20px;
  height:20px;
  }

`;

const ProjectList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 10px;
height:100%;
overflow:auto;
`;


const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 40px;
  cursor: pointer;
  padding-left: ${(props) => (props.expanded ? "10px" : "0px")};
  gap: 5px;
  padding:0 10px;
  background: 
  ${(props) => props.selected
        ? lightThemeColors.background
        : lightThemeColors.sidebar.projectsBg};
  transition: 200ms linear;
  border-radius: 5px;
  position: relative;
  border:${(props) => props.selected
        ? `1px solid ${lightThemeColors.primaryColor}`
        : `none`}
`;

const ItemTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5px;
  width: 100%;
  overflow: hidden;
    font-weight: ${(props) =>
        props.selected
            ? 600
            : lightThemeColors.darkColor50};
    font-size: 15px;
    display: flex;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    color: ${(props) =>
        props.selected
            ? lightThemeColors.primaryColor
            : lightThemeColors.darkColor50};
  }
`;
