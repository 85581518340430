import { useCallback, useState } from "react"

export const useExpanded = (init = false) => {
    const [expanded, setExpanded] = useState(init);

    const expand = useCallback(() => {
        setExpanded((prev) => !prev)
    }, [])

    return [expanded, expand]
}