import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider } from 'react-router-dom';
import { router } from './router/router';
import UserContextProvider from './context/useUserContext';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserContextProvider>
    <Toaster position='top-center' />
    <RouterProvider router={router} />
  </UserContextProvider>
);

