import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as DownIcon } from "../../assets/icons/downIcon.svg";
import { useProjectContext } from "../../context/useProjectContext";



const ProjectsMenu = ({
  expandProjectMenu,
}) => {

  const { selectedProject } = useProjectContext();

  const handleOpen = () => {
    expandProjectMenu()
  };

  return (
    <Container>
      <ProjectsBtn onClick={() => handleOpen(true)}>
        {selectedProject
          ? selectedProject.name
          : "Project Name"}
        <DownIcon />
      </ProjectsBtn>

    </Container>
  );
};

export default ProjectsMenu;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height:40px;
`;

const ProjectsBtn = styled.button`
display:flex;
align-items:center;
min-width:150px;
width:100%;
gap:10px;
background: ${lightThemeColors.sidebar.projectsBg};
padding:11px 10px;
border: 0px solid ${lightThemeColors.primaryColor};
border-radius:5px;
font-size: 15px;
font-weight: 500;
line-height: 15px;
text-align: left;
color:${lightThemeColors.primaryColor};
cursor:pointer;
&:disabled{
    opacity:0.3;
}
svg{
    margin-left:auto;
    color:${lightThemeColors.primaryColor};
    height:16px;
`;
const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  background: ${(props) =>
    props.open
      ? props.selected
        ? lightThemeColors.sidebar.selectedItemBg
        : lightThemeColors.sidebar.elementExpandedBg
      : props.selected
        ? lightThemeColors.sidebar.selectedItemBg
        : ""};

  transition: 200ms linear;
  border-radius: 5px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 46px;
  max-width: 30px;
  max-height: 46px;
  transition: 200ms linear;
  padding-left: 10px;
  background: ${(props) =>
    props.selected && !props.open
      ? lightThemeColors.sidebar.selectedItemBg
      : ""};
  margin-right: auto;
  border-radius: 5px;
  svg {
    position: relative;
    height: 20px;
    width: 20px;
    object-fit: contain;
    color: ${(props) =>
    props.selected
      ? lightThemeColors.primaryColor
      : lightThemeColors.darkColorFaded};
  }
`;


