import React from 'react'
import { lightThemeColors } from '../../assets/styles/colors'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'

const LoaderComponent = ({ loading, children }) => {
    return (
        <Container>
            {loading &&
                <LoaderContainer>
                    <ClipLoader
                        size={50}
                        color={lightThemeColors.primaryColor}
                        loading={loading}
                    />
                </LoaderContainer>}
            {children}
        </Container>
    )
}

export default LoaderComponent
const Container = styled.div`
position:relative;
height:100%;
display:flex;
flex-direction:column;
`
const LoaderContainer = styled.div`
position:absolute;
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
z-index:10;
`