import React, { useState } from 'react'
import styled from 'styled-components';
import { lightThemeColors } from '../../assets/styles/colors';
import PrimaryButton from '../../Components/Generic/PrimaryButton';
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIcon.svg"
import { ReactComponent as CopyIcon } from "../../assets/icons/copyIcon.svg"
import { ReactComponent as DotMenuIcon } from "../../assets/icons/dotMenuIcon.svg"
import SecondaryButton from '../../Components/Generic/SecondaryButton';
import { AnimatePresence } from 'framer-motion';
import InviteMembersModal from '../../Components/MembersPage/InviteMembersModal';
import { useMembersContext } from '../../context/useMembersContext';
import { useProjectContext } from '../../context/useProjectContext';
import { useOutletContext } from 'react-router-dom';
import { showGenericError, showSuccessNotification } from '../../helper/Notifications';
import EditMemberModal from '../../Components/MembersPage/EditMemberModal';
import { useUserContext } from '../../context/useUserContext';
import AdminRoleRequired from '../../helper/AdminRoleRequired';

const MembersPage = () => {
    const { selectedProject } = useProjectContext();
    const { user } = useUserContext()
    const { setLoading } = useOutletContext()
    const [showInviteMembers, setShowInviteMembers] = useState(false)
    const { members, getMembers, setMembers, changeMemberRole, deleteMember } = useMembersContext();
    const [editMemberModal, setEditMemberModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null)

    const handleGetMembers = (projectId) => {
        getMembers(
            projectId,
            (response) => { setLoading(false) },
            () => {
                showGenericError()
                setLoading(false)
            },
        )
    }

    const handleEditMember = (member) => {
        if (member.user.id != user.id && hasAdminAccess()) {
            setSelectedMember(member)
            setEditMemberModal(true)
        }
    }

    const praseRole = (role) => {
        let parsed = "Role"

        switch (role) {
            case "admin":
                parsed = "Administrator"
                break;
            case "member":
                parsed = "Member"
                break;

            default:
                break;
        }

        return parsed
    }

    const handleChangeRole = (role) => {
        setLoading(true)
        changeMemberRole(
            selectedProject.id,
            selectedMember.id,
            role,
            (response) => {
                showSuccessNotification("Role changed")
                setEditMemberModal(false);
                setSelectedMember(null)
                handleGetMembers(selectedProject.id);
            },
            (error) => {
                setLoading(false)
                showGenericError()
            }
        )
    }


    const handleRemoveMember = (member) => {
        setLoading(true)
        deleteMember(
            selectedProject.id,
            member.id,
            (response) => {
                showSuccessNotification("Deleted")
                setEditMemberModal(false);
                setSelectedMember(null);
                handleGetMembers(selectedProject.id);
            },
            (error) => {
                showGenericError()
                setLoading(false)
            }
        )
    }

    const hasAdminAccess = () => {
        let found = members.find((item) => item.user.id === user.id)

        if (found && found.role === "admin") {
            return true
        } else {
            return false
        }
    }



    return (
        <Container>
            <AnimatePresence>
                {showInviteMembers &&
                    <InviteMembersModal
                        setIsModalOpen={setShowInviteMembers}
                    />
                }
                {editMemberModal &&
                    <EditMemberModal
                        setIsModalOpen={setEditMemberModal}
                        member={selectedMember}
                        handleChangeRole={handleChangeRole}
                        handleRemoveMember={handleRemoveMember}
                    />
                }
            </AnimatePresence>
            <PageContent>
                <Header>
                    <HeaderTitle>
                        Members
                    </HeaderTitle>
                    {hasAdminAccess() &&
                        <PrimaryButton
                            styled={{ marginLeft: "auto" }}
                            icon={<PlusIcon />}
                            text={"Add members"}
                            onClick={() => setShowInviteMembers(true)}
                        />
                    }
                </Header>
                <ListWrapper>
                    <ListHeader>
                        <HeaderColumn>
                            <p>Name</p>
                        </HeaderColumn>
                        <HeaderColumn>
                            <p>Key</p>
                        </HeaderColumn>
                        <HeaderColumn style={{ minWidth: "10px" }}>
                            <p style={{ marginLeft: "auto" }}>Role</p>
                        </HeaderColumn>
                    </ListHeader>
                    {members?.map((member, index) => (
                        <ListLine style={member.user.name ? {} : { opacity: 0.5 }} key={member.id}>
                            <ListColumn>
                                <h2>{member.user.name || "Invited user"} </h2>
                            </ListColumn>
                            <ListColumn>
                                <h3>{member.user.email}</h3>
                            </ListColumn>
                            <ListColumn style={{ minWidth: "10px" }}>
                                <SecondaryButton
                                    onClick={() => handleEditMember(member)}
                                    text={praseRole(member.role)}
                                    icon={member.user.id != user.id && hasAdminAccess() && <DotMenuIcon />}
                                    style={{ color: lightThemeColors.primaryColor, border: "none", marginLeft: "auto", padding: "0", flexDirection: "row-reverse" }}
                                />

                            </ListColumn>

                        </ListLine>


                    ))}

                </ListWrapper>
            </PageContent>
        </Container >
    )
}

export default MembersPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow:hidden;
  
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow:hidden;
  padding-bottom: 30px;
  padding: 40px 30px;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
display:flex;
align-items:center;
`
const HeaderTitle = styled.h1`
color:${lightThemeColors.darkColor};
font-size: 30px;
font-weight: 600;
line-height: 36px
text-align: left;

`
const ListWrapper = styled.div`
display:flex;
flex-direction:column;
overflow:auto;
`

const Line = styled.div`
display:flex;
align-items:center;
width:100%;
`

const ListHeader = styled(Line)`
background:${lightThemeColors.menuBackground};
padding:13px 20px;
gap:20px;
width:100%;
min-width:450px;
`

const HeaderColumn = styled.div`
display:flex;
width:100%;
min-width:150px;
align-items:center;
p{
color:${lightThemeColors.darkColor50};
font-size: 16px;
font-weight: 600;
line-height: 19px;
text-align: left;
}
`

const ListLine = styled(Line)`
border-bottom:1px solid ${lightThemeColors.menuBackground};
padding:13px 20px;
gap:20px;
width:100%;
min-width:450px;
`
const ListColumn = styled.div`
display:flex;
align-items:center;
width:100%;
min-width:150px;
h3{
text-overflow:ellipsis;
overflow:hidden;
color:${lightThemeColors.darkColor};
font-size: 16px;
font-weight: 400;
line-height: 19px;
}
h2{
text-overflow:ellipsis;
overflow:hidden;
color:${lightThemeColors.darkColor};
font-size: 18px;
font-weight: 600;
line-height: 22px;
}
`
