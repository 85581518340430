import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/CloseModalIcon.svg";
import { motion } from "framer-motion";
import { useOutletContext } from "react-router-dom";
import PrimaryButton from "../Generic/PrimaryButton";
import CustomInput from "../Generic/CustomInput";
import { useKeysContext } from "../../context/useKeysContext";
import { useProjectContext } from "../../context/useProjectContext";
import { showGenericError, showSuccessNotification } from "../../helper/Notifications";

const AddApiKeyModal = ({ isMoldaOpen, setIsModalOpen }) => {
  const { createKey, getKeys } = useKeysContext();
  const { selectedProject } = useProjectContext();


  const [inputValue, setInputValue] = useState("");
  const ref = useRef();

  const { setLoading } = useOutletContext();

  const handleGetKeys = (projectId) => {
    getKeys(
      projectId,
      (response) => {
        setLoading(false)
      },
      (error) => {
        showGenericError()
        setLoading(false)
      }
    )
  }


  const handleCreateApiKey = () => {
    setLoading(true)
    createKey(
      selectedProject.id,
      inputValue,
      (response) => {
        showSuccessNotification("APi Key added")
        handleGetKeys(selectedProject.id)
        setIsModalOpen(false)
      },
      (error) => {
        showGenericError();
        setLoading(false)
      },

    )
  }


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsModalOpen]);


  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);


  return (
    <FullscreenContainer>
      <InviteModal
        ref={ref}
        as={motion.div}
        initial={{ translateX: "65vw" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "65vw" }}
        transition={{ duration: 0.4 }}
      >

        <Line>
          <HeadWrapper>
            <h1>New API Key</h1>
          </HeadWrapper>
          <CloneBtn
            onClick={() => setIsModalOpen(false)}
            style={{ marginLeft: "auto" }}
          >
            <CloseModalIcon />
          </CloneBtn>
        </Line>

        <Line>
          <CustomInput
            value={inputValue}
            onChange={(e) => setInputValue(e.currentTarget.value)}
            label={"Name"}
          />
        </Line>

        <Line>
          <PrimaryButton
            disabled={inputValue.length < 3}
            styled={{ padding: "12px", width: "100%" }}
            text={"Create API Key"}
            onClick={() => handleCreateApiKey()}
          />
        </Line>
      </InviteModal>
    </FullscreenContainer>
  );
};

export default AddApiKeyModal;

const FullscreenContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const InviteModal = styled.div`

  position: fixed;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 400px;
  max-height:600px;
margin-left:auto;
margin-right:auto;
width:100%;
  padding: 30px;
  border-radius:10px;
  gap: 20px;
  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
 display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
    svg{
  width:20px;
  height:20px;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction:column;
  overflow-x:hidden;
  overflow-y:auto;
height:100%;
`
const ListTitle = styled.p`
font-size:14px;
font-weight:500;
color:${lightThemeColors.darkColor};
line-height:20px;
`