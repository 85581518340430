export const defaultSelectOptions = [
    { value: 99, label: "Custom" },
    // { value: 2, label: "Last 7 days" },
    // { value: 3, label: "Last 28 days" },
    { value: 4, label: "Last 30 days" },
    { value: 5, label: "Last 90 days" },
    { value: 6, label: "Last 12 months" },
    { value: 7, label: "Last calendar year" },
];


export const settingsOverviewSelectOptions = [
    { value: 98, label: "Current month" },
    { value: 97, label: "Last month" },
    { value: 96, label: "Last 3 months" },
    { value: 95, label: "Last 6 months" },
    { value: 94, label: "Last year" },
];