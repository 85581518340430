//sidebarNav options
export const API_KEYS = "API Keys";
export const PROJECT = "Project";
export const DOCUMENTATION = "Documentation";
export const MEMBERS = "Members";
export const PROFILE = "Profile";
export const REMOVE_ACCOUNT = "remove_account";
export const DELETE_PROJECT = "delete_project";
export const REMOVE_KEY = "remove_key";
export const REMOVE_MEMBER = "remove_member";
export const CUSTOM = "Custom";
