import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/CloseModalIcon.svg";
import { motion } from "framer-motion";
import cloneDeep from "clone-deep";
import PrimaryButton from "../Generic/PrimaryButton";
import CustomInput from "../Generic/CustomInput";
import SecondaryButton from "../Generic/SecondaryButton";
import EmailRolePair from "../Generic/EmailRolePair";
import { isEmail } from "validator";
import { useProjectContext } from "../../context/useProjectContext";
import { showGenericError, showSuccessNotification } from "../../helper/Notifications";
import { useSearchParams } from "react-router-dom";

const CreateNewProjectModal = ({ isMoldaOpen, setIsModalOpen, setLoading, firstProject }) => {

    const { createProject, setSelectedProject, getProjects } = useProjectContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const [projectName, setProjectName] = useState("");
    const [emailRolePair, setEmailRolePail] = useState([
        {
            email: "",
            role: { value: null, label: "Role" }
        }
    ]);

    const ref = useRef();

    const handleAddKeyValuePair = () => {
        let newEmailRolePair = {
            email: "",
            role: { value: null, label: "Role" },
        };
        setEmailRolePail([...emailRolePair, newEmailRolePair]);
    };

    const handleChangeEmailRolePair = (index, pair) => {
        let clone = cloneDeep(emailRolePair);
        clone[index] = pair;
        setEmailRolePail(clone);
    };

    const handleRemovePair = (index) => {
        let clone = cloneDeep(emailRolePair);
        clone.splice(index, 1);
        setEmailRolePail(clone);
    };

    const handleGetProjects = () => {
        getProjects(
            (response) => {
                setLoading(false);
                setIsModalOpen(false);
            },
            (error) => {
                setLoading(false)
                showGenericError();
                console.log(error)
            },
        )
    }

    const handleCreateProject = () => {
        let members = []

        emailRolePair.forEach((item) => {
            let newItem = {
                email: item.email,
                role: item.role.value
            }

            if (checkValid(newItem)) {
                members.push(newItem)
            }
        })
        setLoading(true)

        createProject(
            projectName,
            members,
            (response) => {
                showSuccessNotification("Project created")
                const project = response.data.project;
                setSearchParams((params) => {
                    params.set("project_id", project.id);
                    return params;
                });
                setSelectedProject(project);
                handleGetProjects();
            },
            (error) => {

                setLoading(false)
                showGenericError()
            }
        )
    }

    const checkValid = (element) => {
        let valid = true;

        if (!isEmail(element.email) || element.role == null) {
            valid = false
        }
        return valid
    }

    useEffect(() => {
        if (firstProject) {
            return
        }
        const checkIfClickedOutside = (e) => {
            if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [setIsModalOpen]);


    useEffect(() => {
        if (firstProject) {
            return
        }
        const close = (e) => {
            if (e.keyCode === 27) {
                setIsModalOpen(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);


    return (
        <FullscreenContainer firstProject={firstProject}>
            <InviteModal
                ref={ref}
                as={motion.div}
                initial={{ translateX: "65vw" }}
                animate={{ translateX: "0" }}
                exit={{ translateX: "65vw" }}
                transition={{ duration: 0.4 }}
            >

                <Line>
                    <HeadWrapper>
                        {firstProject ? <h1>Create your first project</h1>
                            :
                            <h1>Create a new project</h1>
                        }
                    </HeadWrapper>
                    {!firstProject &&
                        <CloneBtn
                            onClick={() => setIsModalOpen(false)}
                            style={{ marginLeft: "auto" }}
                        >
                            <CloseModalIcon />
                        </CloneBtn>
                    }
                </Line>
                <Line>
                    <CustomInput
                        label={"Project name"}
                        name={"projectName"}
                        placeholder={"Enter project name"}
                        value={projectName}
                        onChange={(e) => setProjectName(e.currentTarget.value)}
                    />
                </Line>

                <ListWrapper>
                    <ListTitle>Invite members</ListTitle>
                    {emailRolePair.map((pair, index) => (
                        <EmailRolePair
                            key={index}
                            pair={pair}
                            index={index}
                            handleChangeEmailRolePair={handleChangeEmailRolePair}
                            handleRemove={handleRemovePair}
                        />
                    ))}

                </ListWrapper>

                <Line style={{ justifyContent: "center", marginTop: "-30px" }}>
                    <SecondaryButton
                        style={{ border: "none" }}
                        text={"+ Add new row"}
                        onClick={() => handleAddKeyValuePair()}
                    />
                </Line>
                <Line>
                    <PrimaryButton
                        disabled={projectName.length < 3}
                        styled={{ padding: "12px", width: "100%" }}
                        text={"Create project"}
                        onClick={() => handleCreateProject()}
                    />
                </Line>
            </InviteModal>
        </FullscreenContainer>
    );
};

export default CreateNewProjectModal;

const FullscreenContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:${props => props.firstProject ? lightThemeColors.menuBackground : "rgba(0, 0, 0, 0.1)"};
  z-index: 5;
`;

const InviteModal = styled.div`

  position: fixed;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 400px;
  max-height:600px;
margin-left:auto;
margin-right:auto;
width:100%;
  padding: 30px;
  border-radius:10px;
  gap: 20px;
  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
 display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
  svg{
  width:20px;
  height:20px;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction:column;
  overflow-x:hidden;
  overflow-y:auto;
height:100%;
margin-top:-20px;
`
const ListTitle = styled.p`
font-size:14px;
font-weight:500;
color:${lightThemeColors.darkColor};
line-height:20px;
`