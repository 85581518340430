export const isEmailValid = (email) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(email)) {
    return true;
  }

  return false;
};

export const isNameValid = (name) => {
  if (name.length > 2) {
    return true;
  }

  return false;
};

export const isPasswordValid = (password) => {
  if (password.length > 5) {
    return true;
  }

  return false;
};

export const passwordsMatch = (password1, password2) => {
  if (password1 === password2) {
    return true;
  }
  return false;
};

export const isLengthvalid = (text, minLength) => {
  if (text?.length >= minLength) {
    return true;
  } else {
    return false;
  }
};

export const isUrlSchemeValid = (text) => {
  let regx = /([A-Z,a-z])+(:)+(\/\/)/g;
  if (regx.test(text)) {
    return true;
  }
  return false;
};
export const isValidBundleID = (bundleID) => {
  const regex = /^[A-Za-z](?:[A-Za-z0-9-]*[A-Za-z0-9])?(?:\.[A-Za-z0-9-]+)*$/;
  return regex.test(bundleID);
}