import React, { useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as LogoIcon } from "../../assets/icons/emotiiBigLogo.svg"
import { ReactComponent as Logout } from "../../assets/icons/sidebar/logout.svg"
import { ReactComponent as Profile } from "../../assets/icons/sidebar/profile.svg"
import { ReactComponent as Members } from "../../assets/icons/sidebar/members.svg"
import { ReactComponent as Documentation } from "../../assets/icons/sidebar/documentation.svg"
import { ReactComponent as ApiKeys } from "../../assets/icons/sidebar/apiKeys.svg"
import { ReactComponent as Project } from "../../assets/icons/sidebar/project.svg"

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";


import { useExpanded } from "../../hooks/useExpanded";


import { API_KEYS, MEMBERS, PROJECT } from "../../constants/OptionsConstants";
import { DOCUMENTATION } from "../../constants/OptionsConstants";
import ProjectsMenu from "./ProjectsMenu";
import { AnimatePresence, motion } from "framer-motion";
import NewProjectSideBar from "./NewProjectSideBar";
import { useUserContext } from "../../context/useUserContext";
import { showGenericError } from "../../helper/Notifications";

export const MenuItem = ({ icon, onClick, text, style, selected, logo }) => {
  return (
    <ItemContainer
      onClick={onClick}
      selected={selected}
      style={style}
    >
      {icon &&
        <IconWrapper selected={selected} >
          {icon}
        </IconWrapper>
      }

      {text &&
        <ItemTextContainer selected={selected}>
          <p>{text}</p>
        </ItemTextContainer>
      }
    </ItemContainer>
  );
};

const SideBarMenu = ({ setCreateNewProjectModalOpen }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [projectMenuExpanded, expandProjectMenu] = useExpanded(false)
  const { logoutUser } = useUserContext();

  const navigate = useNavigate();

  const ref = useRef();

  const goTo = (path) => {
    navigate({
      pathname: path,
      search: createSearchParams(searchParams).toString(),
    });
  };

  const handleLogout = () => {
    logoutUser(
      (response) => {
        navigate("/login")
      },
      () => {
        showGenericError()
      }
    )
  }

  const MENU_OPTIONS = [
    {
      option: API_KEYS,
      icon: <ApiKeys />,
      link: "/api-keys",
      action: () => goTo("/api-keys"),
    },
    {
      option: DOCUMENTATION,
      icon: <Documentation />,
      link: "/documentation",
      action: () => goTo("/documentation"),
    },
    {
      option: MEMBERS,
      icon: <Members />,
      link: "/members",
      action: () => goTo("/members"),
    },
    {
      option: PROJECT,
      icon: <Project />,
      link: "/project",
      action: () => goTo("/project"),
    },
  ];


  return (
    <Container ref={ref} >

      <AnimatePresence>
        {projectMenuExpanded &&
          <NewProjectSideBar
            containerRef={ref}
            expandProjectMenu={expandProjectMenu}
            setCreateNewProjectModalOpen={setCreateNewProjectModalOpen}
            isModalOpen={projectMenuExpanded}
          />
        }
      </AnimatePresence>


      <SidebarLogoWrapper >
        <LogoIcon />
      </SidebarLogoWrapper>
      <Content>
        <ProjectsMenu
          expandProjectMenu={expandProjectMenu}
        />
        <List>
          {MENU_OPTIONS.map((item, index) => (
            <MenuItem
              key={index}
              onClick={item.action}
              icon={item.icon}
              text={item.option}
              selected={window.location.pathname.includes(item.link)}
            />
          ))}
        </List>


        <BottomSection>

          <MenuItem
            onClick={() => goTo("/profile")}
            icon={<Profile />}
            text={"Profile"}
            selected={window.location.pathname.includes("/profile")}
          />

        </BottomSection>
        <Separator />
        <MenuBottom >
          <MenuItem
            icon={<Logout />}
            text={"Logout"}
            style={{ borderBottom: "none" }}
            onClick={() => handleLogout()}
          />
        </MenuBottom>
      </Content>


    </Container>

  );
};

export default SideBarMenu;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.menuBackground};
  align-items: center;
  height: 100%;
  min-width:200px;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 40px;
  cursor: pointer;
   padding:0 12px;
  gap: 5px;
  transition: 200ms linear;
  position: relative;
  border-bottom:1px solid ${lightThemeColors.sidebar.menuItemBorder};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 30px;
  max-width: 20px;
  max-height: 30px;
  position: relative;
  transition: 200ms linear;
margin-left:auto;
  border-radius: 5px;
  svg {
    position: relative;
    height: 16px;
    width: 16px;
    object-fit: contain;
    color: ${(props) =>
    props.selected
      ? lightThemeColors.primaryColor
      : lightThemeColors.darkColor};
  }
`;

const ItemTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5px;
  width: 100%;
  overflow: hidden;
  p {
    font-weight: ${(props) => (props.selected ? "600" : "400")};
    font-size: 14px;
    display: flex;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    color: ${(props) =>
    props.selected
      ? lightThemeColors.primaryColor
      : lightThemeColors.darkColor};
  }
`;

const BottomSection = styled.div`
  margin-top: auto;
  width: 100%;
`;

const MenuBottom = styled.div`
  overflow: hidden;
  width:100%;
min-height:60px;
align-items:center;
display:flex;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding:30px 0;
  width: 100%;
  gap: 10px;
  position: relative;
  transition: 400ms linear;

`;
const Separator = styled.div`
  display: flex;
  width: 100%;
  height: 0.5px;
  min-height: 0.5px;
  background: rgba(151, 168, 190, 0.5);
`;

const SidebarLogoWrapper = styled.div`
  margin-left: ${(props) => (props.expanded ? "initial" : "auto")};
  margin-right: auto;
  padding:30px 10px;
  svg {
    width: 100%;
    max-height: 24px;
  }
`;


const Content = styled.div`
display:flex;
flex-direction:column;
overflow:auto;
width:100%;
height:100%;
padding:0 16px;

`