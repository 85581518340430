import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { CUSTOM } from "../../constants/OptionsConstants"
import { defaultSelectOptions } from "../../constants/DatePickerSelectOptions";



const customStyles = {
  container: (provided) => ({
    ...provided,
    padding: 0,
    "&:focus-visible": {
      outline: "none",
    },
  }),
  control: (provided) => ({
    ...provided,
    "&:hover": {
      borderColor: "none",
    },

    border: "none",
    outline: "none",
    minHeight: "unset",
    cursor: "pointer",
    minWidth: "60px",
    overflow: "hidden",
    boxShadow: "none",

  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px"
  }),

  singleValue: (provided) => ({
    ...provided,
    margin: "0 ",
    fontSize: "12px",
    fontWeight: "600",
    color: lightThemeColors.primaryColor,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,

    color: lightThemeColors.primaryColor,
    padding: "0 6px 0 0",
    width: "20px",
    "&:hover": {
      color: lightThemeColors.primaryColor,
    },

  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    padding: "0",
  }),
  option: (provided) => ({
    ...provided,
    padding: "4px 6px",
    fontSize: "12px"
  }),
};

const CustomInputDate = ({ startDate, endDate, handleSetDate, disableCustomDate }) => {
  let sDate = "";
  let eDate = "";
  if (startDate) {
    sDate = moment(startDate).format("DD MMM");
  }
  if (endDate) {
    eDate = moment(endDate).format("DD MMM");
  }

  const ExampleCustomInput = forwardRef(({ value, onClick, disableCustomDate }, ref) => (
    <CustomDateButton
      className="example-custom-input"
      onClick={disableCustomDate ? () => { return } : onClick}
      ref={ref}

    >
      {`${sDate} - ${eDate}`}
    </CustomDateButton>
  ));

  return (
    <ReactDatePicker
      selected={startDate}
      onChange={handleSetDate}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      customInput={<ExampleCustomInput disableCustomDate={disableCustomDate} />}
      popperPlacement="bottom-start" // Adjust this as needed

    />
  );
};

const DatePickerWithOptions = ({
  selectMenuOptions = defaultSelectOptions,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  disableCustomDate,
  initialMonths
}) => {

  const [months, setMonths] = useState(initialMonths ||
    { value: 4, label: "Last 30 days" },
  );

  const handleSetDate = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    setMonths({
      value: 99,
      label: CUSTOM,
    });
  };

  const handleOptionSelect = (value) => {
    let startDate = new Date();
    let endDate = new Date();

    switch (value.value) {
      case 2:
        startDate = moment(startDate).utc().subtract(6, "days").startOf("day");
        endDate = moment().endOf("day")
        break;
      case 3:
        startDate = moment(startDate).utc().subtract(27, "day").startOf("day");
        endDate = moment(endDate).endOf("day")
        break;

      case 4:
        startDate = moment(startDate).utc().subtract(29, "day").startOf("day");
        endDate = moment(endDate).endOf("day")
        break;
      case 5:
        startDate = moment(startDate).utc().subtract(89, "day").startOf("day");
        endDate = moment(endDate).endOf("day")
        break;
      case 6:
        startDate = moment(startDate).utc().subtract(12, "month");
        endDate = moment(endDate).endOf("day")
        break;
      case 7:
        startDate = moment(startDate).utc().subtract(1, "year").startOf("year");
        endDate = moment(endDate).utc().subtract(1, "year").endOf("year");
        break;


      case 98:
        startDate = moment(startDate).utc().startOf("month");
        endDate = moment(endDate).endOf("day")
        break;
      case 97:
        startDate = moment().utc().subtract(1, "month").startOf("month");
        endDate = moment().subtract(1, "month").endOf("month")
        break;
      case 96:
        startDate = moment().utc().subtract(3, "month").startOf("month");
        endDate = moment().subtract(1, "month").endOf("month");
        break;
      case 95:
        startDate = moment().utc().subtract(6, "month").startOf("month");
        endDate = moment().subtract(1, "month").endOf("month")
        break;
      case 94:
        startDate = moment().utc().subtract(1, "year").startOf("month");
        endDate = moment().endOf("month")
        break;

      default:
        break;
    }

    setMonths(value);
    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
  };

  useEffect(() => {
    if (!startDate && !endDate) {
      setMonths({
        value: 99,
        label: CUSTOM,
      });
    }
  }, [startDate, endDate]);

  return (
    <Container>
      <Select
        styles={customStyles}
        value={months}
        options={selectMenuOptions}
        onChange={(e) => handleOptionSelect(e)}
        isSearchable={false}
      />

      <CustomInputDate
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleSetDate={handleSetDate}
        disableCustomDate={disableCustomDate}
      />
    </Container>
  );
};

export default DatePickerWithOptions;
const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${lightThemeColors.primaryColor};
  border-radius: 5px;
  position: relative;
  max-height: 30px;
  width: fit-content;
  position:relative;
`;
const CustomDateButton = styled.button`
  border: none;
  min-width: 60px;
  padding: 2px 6px;
  border-radius: 5px;
  background: none;
  cursor: pointer;
  border-left: 1px solid ${lightThemeColors.primaryColor};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: ${lightThemeColors.primaryColor};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  &:active: {
    border: 1px soldi red;
  }
`;
