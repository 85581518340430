import { DELETE, GET, POST, PUT } from "../API";


export const signInAPICall = (email, password, otpCode, onSuccess, onError) => {
    const data = {
        email: email,
        password: password,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };

    if (otpCode != "") {
        data.otp_code = otpCode
    }

    POST(process.env.REACT_APP_API_PATH +
        "/identity/api_users/sign_in", data, onSuccess, onError, false);
};

export const logoutAPICall = (onSuccess, onError) => {
    DELETE(
        process.env.REACT_APP_API_PATH +
        "/identity/api_users/sign_out",
        onSuccess, onError);
};

export const acceptInviteAPICall = (
    invitationToken,
    name,
    password,
    onSuccess,
    onError
) => {
    const data = {
        name: name,
        invitation_token: invitationToken,
        password: password,
        client_id: process.env.REACT_APP_CLIENT_ID,
    };

    POST(
        process.env.REACT_APP_API_PATH + "/identity/api_users/accept_invite",
        data,
        onSuccess,
        onError
    );
};


export const createAccountAPICall = (
    email,
    password,
    name,
    onSuccess,
    onError
) => {
    const data = {
        email: email,
        password: password,
        name: name,
        client_id: process.env.REACT_APP_CLIENT_ID,
    };

    POST(
        process.env.REACT_APP_API_PATH + "/identity/api_users/create",
        data,
        onSuccess,
        onError
    );
};

export const resetPasswordAPICall = (email, onSuccess, onError) => {
    const data = {
        email: email,
    };

    POST(
        process.env.REACT_APP_API_PATH + "/identity/api_users/forgot_password",
        data,
        onSuccess,
        onError
    );
};


export const updateUserAPICall = (data, onSuccess, onError) => {
    PUT(
        process.env.REACT_APP_API_PATH + "/identity/api_users/update",
        data,
        onSuccess,
        onError
    );
};

export const changePasswordAPICall = (oldPassword, newPassword, otpCode, onSuccess, onError) => {
    const data = {
        old_password: oldPassword,
        new_password: newPassword,
    };

    if (otpCode != "") {
        data.otp_code = otpCode
    }

    POST(
        process.env.REACT_APP_API_PATH + "/identity/api_users/change_password",
        data,
        onSuccess,
        onError
    );
};

export const confirmForgotPasswordAPICall = (token, password, onSuccess, onError) => {

    const data = {
        new_password: password,
        reset_token: token,
    };

    POST(
        process.env.REACT_APP_API_PATH + "/identity/api_users/confirm_forgot_password",
        data,
        onSuccess,
        onError
    );
};

export const currentUserAPICall = (onSuccess, onError) => {
    GET(
        process.env.REACT_APP_API_PATH + "/identity/api_users/current_user_details",
        onSuccess,
        onError
    );
};

export const enable2FAAPICall = (enabled, otpCode, onSuccess, onError) => {
    const data = {
        enabled: enabled,
        otp_code: otpCode
    }
    POST(
        process.env.REACT_APP_API_PATH + "/identity/api_users/set_2fa_enabled",
        data,
        onSuccess,
        onError
    );
};

export const isOtpEnabledAPICall = (email, onSuccess, onError) => {
    const data = {
        email: email
    }
    POST(
        process.env.REACT_APP_API_PATH + "/identity/api_users/otp_enabled",
        data,
        onSuccess,
        onError
    );
};

export const getOTPQrcodeAPICall = (onSuccess, onError) => {
    GET(
        process.env.REACT_APP_API_PATH + "/identity/api_users/otp_qr",
        onSuccess,
        onError
    );
};

export const removeUserAPICAll = (email, onSuccess, onError) => {
    DELETE(
        process.env.REACT_APP_API_PATH + `/users/remove_user?email=${email}`,
        onSuccess,
        onError
    );
};

