import React, { useEffect, useState } from 'react'
import CustomInput from './CustomInput';
import { lightThemeColors } from '../../assets/styles/colors';
import Select from "react-select";
import styled from 'styled-components';
import SecondaryButton from './SecondaryButton';
import { ReactComponent as Bin } from "../../assets/icons/bin-emotii.svg"

const EmailRolePair = ({
    pair,
    handleRemove,
    handleChangeEmailRolePair,
    index,
    noDelete,
    readOnlyInput
}) => {

    const [inputValue, setInputValue] = useState(pair.email)
    const [role, setRole] = useState(pair.role);

    const selectOptions = [
        { value: "admin", label: "Administrator" },
        { value: "member", label: "Member" },
    ];

    const handleInputChange = (e) => {
        if (readOnlyInput) {
            return
        }
        setInputValue(e.currentTarget.value)
    }


    const customStyles = {
        container: (provided) => ({
            ...provided,
            "&:focus-visible": {
                outline: "none",
                border: "none"
            },
            border: "none",
            padding: 0,
            margin: 0,

        }),

        control: (provided) => ({
            ...provided,
            borderRadius: "5px",
            outline: "none",
            border: "none",
            padding: 0,
            "&:hover": {
                border: "none",
                outline: "none"
            },
        }),

        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
            padding: 0,
        }),

        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "0 10px 0 0",
            color: lightThemeColors.primaryColor
        }),


        menu: (provided) => ({
            ...provided,
            minWidth: "110px",

        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: "0",
        }),


        singleValue: (provided) => ({
            ...provided,
            fontSize: "14px",
            fontWeight: "500",
            color: lightThemeColors.primaryColor,
            padding: 0,

        }),
        Menu: (provided) => ({
            ...provided,
        })

    };


    useEffect(() => {
        let newPair = {
            email: inputValue,
            role: role,
        };
        handleChangeEmailRolePair(index, newPair);

    }, [inputValue, role]);


    return (
        <Container>
            <CustomInput
                placeholder={"Member email"}
                value={inputValue}
                onChange={(e) => handleInputChange(e)}
                customButtonStyle={{ minWidth: "fit-content" }}
                readOnly={readOnlyInput}
                customButton={
                    <Select
                        isSearchable={false}
                        styles={customStyles}
                        value={role}
                        menuPosition='fixed'
                        closeMenuOnScroll
                        options={selectOptions}
                        onChange={(e) => setRole(e)}
                    />

                }
            />
            {(index != 0 && !noDelete) &&
                <SecondaryButton
                    style={{ border: "none", padding: "0" }}
                    icon={<Bin />}
                    onClick={() => handleRemove(index)}
                />
            }
        </Container>

    )
}

export default EmailRolePair

const Container = styled.div`
display:flex;
align-items:center;
gap:10px;
width:100%;

`