import {
    createContext,
    useState,
    useContext,
    useCallback,
} from "react";
import { changeMemberRoleAPICall, getMembersAPICall, inviteMembersAPICall, removeMemberAPICAll } from "../api/members/membersServive";


export const MembersContext = createContext({
    members: [],
    setMembers: () => { },
    getMembers: () => { },
    inviteMembers: () => { },
    deleteMember: () => { },
    changeMemberRole: () => { }


});

const MembersContextProvider = ({ children, setLoading }) => {
    const [members, setMembers] = useState(undefined);

    const getMembers = useCallback(async (projectId, onSuccess, onError) => {
        getMembersAPICall(
            projectId,
            function (response) {
                setMembers(response.data.members);
                onSuccess(response);
            },
            onError
        );
    });

    const inviteMembers = useCallback(async (projectId, members, onSuccess, onError) => {
        inviteMembersAPICall(
            projectId,
            members,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const deleteMember = useCallback(async (projectId, memberId, onSuccess, onError) => {
        removeMemberAPICAll(
            projectId,
            memberId,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const changeMemberRole = useCallback(async (projectId, memberId, role, onSuccess, onError) => {
        changeMemberRoleAPICall(
            projectId,
            memberId,
            role,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });


    return (
        <MembersContext.Provider
            value={{
                members, setMembers,
                getMembers, inviteMembers, deleteMember, changeMemberRole
            }}
        >
            {children}
        </MembersContext.Provider>
    );
};

export const useMembersContext = () => useContext(MembersContext);

export default MembersContextProvider;
