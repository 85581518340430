import {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from "react";
import { createKeyAPICall, deleteKeyAPICall, getKeysAPICall } from "../api/keys/keyService";


export const KeysContext = createContext({
    keys: [],
    setKeys: () => { },
    getKeys: () => { },
    createKey: () => { },
    deleteKey: () => { }


});

const KeysContextProvider = ({ children, setLoading }) => {
    const [keys, setKeys] = useState(undefined);

    const getKeys = useCallback(async (projectId, onSuccess, onError) => {
        getKeysAPICall(
            projectId,
            function (response) {
                setKeys(response.data.keys);
                onSuccess(response);
            },
            onError
        );
    });

    const createKey = useCallback(async (projectId, name, onSuccess, onError) => {
        createKeyAPICall(
            projectId,
            name,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });

    const deleteKey = useCallback(async (projectId, keyId, onSuccess, onError) => {
        deleteKeyAPICall(
            projectId,
            keyId,
            function (response) {
                onSuccess(response);
            },
            onError
        );
    });


    return (
        <KeysContext.Provider
            value={{
                keys, setKeys,
                getKeys, createKey, deleteKey
            }}
        >
            {children}
        </KeysContext.Provider>
    );
};

export const useKeysContext = () => useContext(KeysContext);

export default KeysContextProvider;
