import React, { useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/CloseModalIcon.svg";
import Select from "react-select";
import { motion } from "framer-motion";
import { useOutletContext } from "react-router-dom";
import cloneDeep from "clone-deep";
import PrimaryButton from "../Generic/PrimaryButton";
import EmailRolePair from "../Generic/EmailRolePair";
import DeleteConfirmationChild from "../Generic/DeleteConfirmationWithChild";
import SecondaryButton from "../Generic/SecondaryButton";
import { REMOVE_MEMBER } from "../../constants/OptionsConstants";

const EditMemberModal = ({ isMoldaOpen, setIsModalOpen, member, handleChangeRole, handleRemoveMember }) => {


    const [showConfirm, setShowConfirm] = useState(false);

    const ref = useRef();

    const praseRole = (role) => {
        let parsed = {
            value: null, label: "Role"
        }

        switch (role) {
            case "admin":
                parsed = {
                    value: "admin", label: "Administrator"
                }
                break;
            case "member":
                parsed = {
                    value: "member", label: "Member"
                }
                break;

            default:
                break;
        }

        return parsed
    }

    const [emailRolePair, setEmailRolePail] = useState(
        {
            email: member.user.email,
            role: praseRole(member.role)
        }
    );


    const handleChangeEmailRolePair = (index, pair) => {
        let clone = cloneDeep(emailRolePair)
        clone.role = pair.role
        setEmailRolePail(clone)
    };


    const checkChanges = () => {
        let changes = false;

        if (emailRolePair.role.value !== member.role) {
            changes = true
        }
        return changes
    }


    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
                setIsModalOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [setIsModalOpen]);


    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setIsModalOpen(false);
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, []);



    return (
        <FullscreenContainer>
            <InviteModal
                ref={ref}
                as={motion.div}
                initial={{ translateX: "65vw" }}
                animate={{ translateX: "0" }}
                exit={{ translateX: "65vw" }}
                transition={{ duration: 0.4 }}
            >

                <Line>
                    <HeadWrapper>
                        <h1>Edit member</h1>
                    </HeadWrapper>
                    <CloneBtn
                        onClick={() => setIsModalOpen(false)}
                        style={{ marginLeft: "auto" }}
                    >
                        <CloseModalIcon />
                    </CloneBtn>
                </Line>

                <ListWrapper>
                    <ListTitle>Members</ListTitle>
                    <EmailRolePair
                        pair={emailRolePair}
                        handleChangeEmailRolePair={handleChangeEmailRolePair}
                        noDelete
                        readOnlyInput
                    />
                </ListWrapper>
                <Line>
                    <PrimaryButton
                        disabled={!checkChanges()}
                        styled={{ padding: "12px", width: "100%" }}
                        text={"Save changes"}
                        onClick={() => handleChangeRole(emailRolePair.role.value)}
                    />
                </Line>
                <Line>
                    <DeleteConfirmationChild
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        action={handleRemoveMember}
                        item={member}
                        variant={REMOVE_MEMBER}
                    >
                        <SecondaryButton
                            variant={lightThemeColors.authentication.inputErrorColor}
                            style={{ padding: "12px", width: "100%" }}
                            text={"Remove member"}
                            onClick={() => setShowConfirm(true)}
                        />
                    </DeleteConfirmationChild>
                </Line>
            </InviteModal>
        </FullscreenContainer>
    );
};

export default EditMemberModal;

const FullscreenContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const InviteModal = styled.div`

  position: fixed;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 400px;
  max-height:600px;
margin-left:auto;
margin-right:auto;
width:100%;
  padding: 30px;
  border-radius:10px;
  gap: 20px;
  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
 display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
    svg{
  width:20px;
  height:20px;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction:column;
  overflow-x:hidden;
  overflow-y:auto;
height:100%;
`
const ListTitle = styled.p`
font-size:14px;
font-weight:500;
color:${lightThemeColors.darkColor};
line-height:20px;
`