import { DELETE, GET, PATCH, POST } from "../API";

export const getMembersAPICall = (
    projectId,
    onSuccess,
    onError
) => {

    GET(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/members`,
        onSuccess,
        onError
    );
};

export const inviteMembersAPICall = (
    projectId,
    members,
    onSuccess,
    onError
) => {
    const data = {
        members: members,
    };

    POST(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/members/invite`,
        data,
        onSuccess,
        onError
    );
};

export const removeMemberAPICAll = (
    projectId,
    memberId,
    onSuccess,
    onError
) => {

    DELETE(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/members/${memberId}/remove`,
        onSuccess,
        onError
    );


};

export const changeMemberRoleAPICall = (
    projectId,
    memberId,
    role,
    onSuccess,
    onError
) => {
    const data = {
        role: role
    }
    PATCH(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/members/${memberId}/change_role`,
        data,
        onSuccess,
        onError
    );
}