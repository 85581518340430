import { DELETE, GET, POST } from "../API";

export const getProjectsAPICall = (
    onSuccess,
    onError
) => {


    GET(
        process.env.REACT_APP_API_PATH + "/public_api/projects",
        onSuccess,
        onError
    );
};

export const createProjectAPICall = (
    name,
    members,
    onSuccess,
    onError
) => {
    const data = {
        name: name,
        members: members
    };

    POST(
        process.env.REACT_APP_API_PATH + "/public_api/projects/create",
        data,
        onSuccess,
        onError
    );
};

export const deleteProjectAPICall = (
    projectId,
    onSuccess,
    onError
) => {

    DELETE(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}`,
        onSuccess,
        onError
    );
};

export const getProjectUsageAPICall = (
    projectId,
    startDate,
    endDate,
    onSuccess,
    onError
) => {

    const data = {
        start_date: startDate,
        end_date: endDate
    }

    POST(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/usage`,
        data,
        onSuccess,
        onError
    );
};